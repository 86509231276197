<template>
  <div class="pagination-container">
    <p v-if="currentPageLength > 0" class="pagination-container__desc">
      {{
        $t("showing_results", {
          length: currentPageLength,
          total: totalPage,
        })
      }}
    </p>
    <b-pagination
      v-if="lastPage > 1"
      v-model="page"
      @pageChanged="passPage"
      :total-rows="totalPage"
      :per-page="perPage"
      align="center"
      last-number
      first-number
    ></b-pagination>
  </div>
</template>
<script>
import "./pagination.scss";
export default {
  props: ["lastPage", "totalPage", "perPage", "currentPageLength"],
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    console: () => console,
  },
  methods: {
    passPage() {
      this.$emit("handlePageChange", this.page);
    },
  },
  watch: {
    page: {
      handler(newVal) {
        if (newVal) {
          this.passPage();
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
