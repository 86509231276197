<template>
  <div :class="`${wrapperStyle}`">
    <label v-if="externalLabel">{{ label }}</label>
    <v-select
      :items="list"
      :item-text="itemText ? itemText : 'name'"
      :item-value="itemValue ? itemValue : 'id'"
      :label="!externalLabel ? label : ''"
      :multiple="multiple"
      :dense="dense"
      :outlined="outlined"
      hide-details
      v-model="inputValue"
      @change="handleValueChange"
      :chips="isChip"
      :disabled="false"
      :loading="false"
      :menu-props="{
        left: true,
        offsetY: true,
        minWidth: minWidth,
        maxWidth: maxWidth,
      }"
    >
      <template v-slot:selection="{ index }" v-if="isCounter">
        <span v-if="index === 0" class="grey--text text-caption">
          {{ `(${inputValue.length} ${counterValue})` }}
        </span>
      </template>
    </v-select>
  </div>
</template>
<script>
import "./select.scss";
export default {
  props: [
    "id",
    "list",
    "isChip",
    "isCounter",
    "counterValue",
    "itemText",
    "itemValue",
    "label",
    "multiple",
    "dense",
    "outlined",
    "minWidth",
    "maxWidth",
    "externalLabel",
    "wrapperStyle",
  ],

  data() {
    return {
      inputValue: [],
    };
  },
  computed: {
    console: () => console,
  },
  methods: {
    handleValueChange() {
      this.$emit("handleSelectChange", { id: this.id, value: this.inputValue });
    },
  },
};
</script>
<style lang="scss" scoped></style>
