<template>
  <v-app>
    <div
      class="card-header border-0 py-5 align-items-center d-flex justify-content-between"
    >
      <h3 class="card-title align-items-start flex-column m-0 py-3">
        <span class="card-label font-weight-bolder text-dark"
          >Talents List</span
        >
      </h3>

      <button
        class="btn btn-light font-weight-bolder text-dark-75"
        v-b-toggle.challenge-talent-filter
      >
        <v-icon>mdi-tune</v-icon> Filter
      </button>
    </div>
    <TalentFilter />
    <div class="card-body pt-0 pb-3 white-bg">
      <TableComponent
        :headerList="tableHeaderList"
        :bodyList="handleTableBodyList()"
      />
      <PaginationComponent
        v-if="talentList.meta"
        :lastPage="talentList.meta.last_page"
        :totalPage="talentList.meta.total"
        :currentPageLength="handleTableBodyList().length"
        :perPage="10"
        @handlePageChange="handlePagination"
      />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableComponent from "@/components/shared/table/table.vue";
import PaginationComponent from "@/components/shared/pagination/pagination.vue";
import { convertTimstampToDateTime } from "@/helpers";
import TalentFilter from "../component/talentFilter/index.vue";
import { truncate } from "@/helpers";

export default {
  components: { TableComponent, PaginationComponent, TalentFilter },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Coding Challenge" }]);
    this.getTalentListRequest(1);
  },
  data() {
    let tableHeaderList = [
      { title: "Talent Name", style: "" },
      { title: "Email", style: "" },
      { title: "Country", style: "" },
      { title: "Mobile No.", style: "" },
      { title: "Role", style: "" },
      { title: "Registration Date", style: "" },
      { title: "Actions", style: "" },
    ];
    return {
      tableHeaderList,
    };
  },
  computed: {
    console: () => console,
    ...mapGetters({
      talentList: "codingChallenge/getTalentList",
      talentSearchPayload: "codingChallenge/getTalentSearchPayload",
    }),
  },
  methods: {
    truncate,
    convertTimstampToDateTime,
    ...mapActions({
      getTalentList: "codingChallenge/getTalentListRequest",
    }),
    getTalentListRequest(page) {
      const { roles, countries, textInput, start_date, end_date } =
        this.talentSearchPayload;
      let payload = {
        params: {
          perPage: 10,
          page: page,
          competitors: 1,
        },
      };
      if (roles) payload.params.multiRoles = roles;
      if (countries) payload.params.country = countries;
      if (textInput) payload.params.search = textInput;
      if (start_date) {
        payload.params.registerDate = [start_date];
      }
      if (end_date) {
        payload.params.registerDate = [end_date];
      }
      if (start_date && end_date)
        payload.params.registerDate = [start_date, end_date];

      this.getTalentList(payload);
    },
    handleTableBodyList() {
      let bodyList = [];

      this.talentList?.data?.map((item) => {
        let itemUpdated = {
          itemInTable: [
            this.truncate(item.first_name + " " + item.last_name, 15),
            item.email,
            item.country?.name,
            item.phone ? item.country_code + item.phone : "",
            item.role?.name,
            this.convertTimstampToDateTime(item.register_at).date,
            `<a href=/dashboard/talent/${item.id} class="btn btn-icon btn-light btn-sm" v-b-tooltip title="View & Edit"><span class="svg-icon svg-icon-md svg-icon-primary"><i class="flaticon-eye text-dark"></i></span></a>`,
          ],
          originItem: item,
        };
        bodyList = [...bodyList, itemUpdated];
      });
      return bodyList;
    },
    handlePagination(page) {
      this.getTalentListRequest(page);
    },
  },

  watch: {
    talentList: {
      handler(newVal) {
        if (newVal) {
          this.handleTableBodyList();
        }
      },
      deep: true,
    },
  },
};
</script>
