<template>
  <div :class="`${wrapperStyle} w-100`">
    <label v-if="label">{{ label }}</label>
    <div>
      <date-picker
        v-model="dateValue"
        valueType="format"
        :placeholder="placeholder"
        @handleChange="handleValueChange"
        :disabled-date="disabledRange"
        :input-attr="{ name: id, id: id }"
        :input-class="inputClass"
        :disabled="false"
      ></date-picker>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { DatePicker },
  props: [
    "label",
    "placeholder",
    "id",
    "disabledDate",
    "disabledDateRange",
    "inputClass",
    "wrapperStyle",
  ],
  data() {
    return {
      dateValue: null,
    };
  },
  computed: {
    console: () => console,
  },
  methods: {
    handleValueChange() {
      this.$emit("handleDateChange", { id: this.id, value: this.dateValue });
    },
    disabledRange(date) {
      if (this.disabledDateRange) {
        if (this.id === "end_date") {
          return date < new Date(this.disabledDateRange);
        } else if (this.id === "start_date") {
          return date > new Date(this.disabledDateRange);
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    dateValue: {
      handler() {
        this.handleValueChange();
      },
      deep: true,
    },
  },
};
</script>
