<template>
  <div class="table-responsive tableComponent">
    <table
      class="table table-head-custom table-vertical-center table-head-bg table-borderless"
    >
      <thead>
        <tr class="text-left">
          <th
            v-for="(item, index) in headerList"
            :key="index"
            :class="item.style"
          >
            {{ item.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in bodyList" :key="index">
          <td
            class="font-weight-bolder font-size-lg"
            v-for="(ele, index) in item.itemInTable"
            :key="index"
            v-html="ele ? ele : '-'"
          ></td>
        </tr>

        <tr v-if="bodyList.length == 0">
          <td :colspan="headerList.length" class="noResult">
            <span class="font-weight-bolder font-size-lg"
              >No Results Found</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import "./table.scss";
export default {
  props: ["headerList", "bodyList"],
  computed: {
    console: () => console,
  },
};
</script>
<style lang="scss" scoped></style>
