<template>
  <b-collapse id="challenge-talent-filter">
    <div class="card-header py-0 border-0 pb-4">
      <div class="row" :key="componentKey">
        <div class="col-12 mb-3">
          <label>Search With Name or Email or SN</label>
          <v-text-field
            outlined
            dense
            hide-details
            v-model.trim="searchValues.textInput"
          ></v-text-field>
        </div>

        <SelectComponent
          wrapperStyle="col-md-6 col-12 mb-3"
          :list="rolesList"
          label="Roles"
          :multiple="true"
          :outlined="true"
          :dense="true"
          @handleSelectChange="handleSelect"
          id="roles"
          :isCounter="true"
          counterValue="Roles"
          :externalLabel="true"
        />
        <SelectComponent
          wrapperStyle="col-md-6 col-12 mb-3"
          :list="countryList"
          label="Country"
          :multiple="true"
          :outlined="true"
          :dense="true"
          @handleSelectChange="handleSelect"
          id="countries"
          :isCounter="true"
          counterValue="Countries"
          :externalLabel="true"
        />
        <DatePickerComponent
          wrapperStyle="col-md-4 col-6 mb-3"
          label="Start Date"
          placeholder="Select date"
          id="start_date"
          @handleDateChange="handleDate"
          :disabledDateRange="searchValues.end_date"
        />
        <DatePickerComponent
          wrapperStyle="col-md-4 col-6 mb-3"
          label="End Date"
          placeholder="Select date"
          id="end_date"
          @handleDateChange="handleDate"
          :disabledDateRange="searchValues.start_date"
        />

        <!-- <div class="d-flex"> -->
        <div class="col-6 col-md-2 mt25">
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-5 col"
            @click="submitFilter()"
          >
            Apply
          </button>
        </div>
        <div class="col-6 col-md-2 mt25">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-5 col"
            @click="resetFiter"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </b-collapse>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DatePickerComponent from "@/components/shared/datepicker/datepicker.vue";
import SelectComponent from "@/components/shared/select/select.vue";

export default {
  components: { DatePickerComponent, SelectComponent },
  mounted() {
    this.getRolesRequest();
    this.getCountryList();
  },

  data() {
    return {
      componentKey: 0,
      searchValues: {
        start_date: null,
        end_date: null,
        roles: null,
        countries: null,
        textInput: null,
      },
    };
  },
  computed: {
    console: () => console,
    ...mapGetters({
      rolesList: "talents/getRoles",
      countryList: "shared/getCountryList",
    }),
  },
  methods: {
    ...mapActions({
      getRolesRequest: "talents/getRoles",
      getCountryList: "shared/getCountryList",
      getTalentList: "codingChallenge/getTalentListRequest",
      talentSearchPayload: "codingChallenge/talentSearchPayload",
    }),
    handleDate(date) {
      this.searchValues[date.id] = date.value;
    },
    handleSelect(data) {
      this.searchValues[data.id] = data.value;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    submitFilter() {
      const { roles, countries, textInput, start_date, end_date } =
        this.searchValues;
      let payload = {
        params: {
          perPage: 10,
          page: 1,
          competitors: 1,
          multiRoles: roles,
          country: countries,
        },
      };
      if (textInput) payload.params.search = textInput;
      if (start_date) {
        payload.params.registerDate = [start_date];
      }
      if (end_date) {
        payload.params.registerDate = [end_date];
      }
      if (start_date && end_date) {
        payload.params.registerDate = [start_date, end_date];
      }

      if (roles || countries || textInput || start_date || end_date) {
        this.getTalentList(payload);
      } else {
        this.$toasted.show("Please Add At Least One Filter", {
          type: "error",
          icon: "error",
        });
      }
    },
    resetFiter() {
      this.forceRerender();
      this.searchValues = {
        start_date: null,
        end_date: null,
        roles: null,
        countries: null,
        textInput: null,
      };
      let payload = {
        params: {
          perPage: 10,
          page: 1,
          competitors: 1,
        },
      };
      this.getTalentList(payload);
    },
  },
  watch: {
    searchValues: {
      handler(newVal) {
        this.talentSearchPayload(newVal);
      },
      deep: true,
    },
  },
};
</script>
